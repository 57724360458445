import { render, staticRenderFns } from "./orderMaterialList.vue?vue&type=template&id=324b7285&scoped=true&"
import script from "./orderMaterialList.vue?vue&type=script&lang=js&"
export * from "./orderMaterialList.vue?vue&type=script&lang=js&"
import style0 from "./orderMaterialList.vue?vue&type=style&index=0&id=324b7285&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324b7285",
  null
  
)

export default component.exports