import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
import $orderReq from '@/request/orderApi.js'
const Index = {
  name: 'orderMaterialList',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      visibl1e: false,
      visible: false,
      visible1: false,
      adSearchForm: [],
      // 发货数量
      TypeList: [
        {
          value: 1,
          label: '已发清'
        }, {
          value: 2,
          label: '未发清'
        }
      ],
      // 物料
      adSearchForm1: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '物料编号', value: 'InvCode' },
        { name: '品牌', value: 'BrandName' },
        { name: '物料名称', value: 'InvName' },
        { name: '物料类型', value: 'MaterialTypeName' },
        { name: '物料简称', value: 'InvShotName' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '注册证号', value: 'CertificateName' },
        { name: 'UDI码', value: 'DINum' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '生产厂家', value: 'OrigFactory' },
        { name: '入库单号', value: 'InStockOrderID' },
        { name: '操作人', value: 'OperationNickName' },
        { name: '时间', value: 'date', date: true }
      ],
      // 订单
      adSearchForm2: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '送达方', value: 'CustomerCompanyName' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '时间', value: 'date', date: true }
      ],
      formSearch: {
        PageIndex: 1,
        KeyWords: '', // '关键字',
        Type: '', // 发货数量
        ASNID: '', //  '预入库单号',
        PurchaseOrderID: '', //  '采购订单号',
        InvCode: '', //  '物料编号',
        BrandName: '', // '品牌',
        InvName: '', // '物料名称',
        MaterialTypeName: '', // '物料类型',
        InvShotName: '', //  '物料简称',
        SellCompanyName: '', //  '供应商',
        CertificateName: '', //  '注册证号',
        DINum: '', // 'UDI码',
        ManuPartNum: '', // '原厂编号',
        OrigFactory: '', //  '生产厂家',
        BeginDate: '', //  '开始时间',
        EndDate: '', // '结束时间',
        InStockOrderID: '', //  入库单号,
        OperationNickName: '', // 操作人
        CustomerCompanyName: '', // 送达方,
        IdxList: '',
        date: []
      },
      arr: [],
      SelectType: 1,
      arrForm: [],
      selects: []
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 翻页
    handleCurrentChange(val) {
      this.formSearch.PageIndex = val
      if (this.SelectType === 1) {
        this.getTableDataAsync()
      }
      if (this.SelectType === 2) {
        this.getTableDataAsync1()
      }
    },
    // 组成提交数据
    setFormData(row) {
      const obj = {
        IDX: '', // 明细IDX,
        LotNum: '', // 入库批号/序列号,
        ProductDate: '', // 生产日期,
        ExpireDate: '', // 效期,
        InStockNum: '', // 入库数量,
        ExternalPacking: '', // 外包装,
        Temperature: '', // 到货温度,
        Location: '', // 入库仓库
        DetailID: ''
      }
      for (const key in obj) {
        obj[key] = row[key]
      }
      if (row.isNoSet) { delete obj.IDX }
      if (!row.isNoSet) { delete obj.DetailID }
      if (row.children && row.children.length > 0) {
        row.children.forEach(item2 => {
          this.setFormData(item2)
        })
      }
      this.arrForm.push(obj)
    },
    // 判断是否超出
    isAllNums(row) {
      if (row.ReceiveNum * 1 >= row.Num * 1) return false
      if (row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
      if (row.children && row.children.length > 0) {
        // eslint-disable-next-line no-unused-vars
        let childrenNum = 0
        row.children.forEach(item => {
          childrenNum += item.InStockNum * 1
        })
        if (childrenNum * 1 + row.ReceiveNum * 1 + row.InStockNum * 1 >= row.Num * 1) return false
        // if (row.Num === row.ReceiveNum) return false
      }
      return true
    },
    // 拆分
    chaifen(index, row) {
      if (!this.isAllNums(row)) return this.$message.info('已入数量已经等于总数量')
      // if (row.Num === row.ReceiveNum) return this.$message.info('已入数量已经等于总数量')
      console.log(index)
      let temp = {}
      temp = Object.assign(temp, row)
      temp.IDX = Date.now()
      temp.isNoSet = true
      temp.InStockNum = 0
      delete temp.children
      if (row.children && row.children.length > 0) {
        return row.children.push(temp)
      }
      this.$set(row, 'children', [])
      console.log(temp)
      row.children.push(temp)
      console.log(this.tableData)
    },
    // 确认入库
    OrderASNReceiveLogSave() {
      if (this.selects.length <= 0) return this.$message.error('没有选择任何一项')
      this.selects.forEach(item => {
        this.setFormData(item)
      })
      this.$api.OrderASNReceiveLogSave({ ParamsList: this.arrForm }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.refreshCurrentChange()
          // 执行业务逻辑
        } else {
          this.$message.error('错误：', res.RetMsg)
        }
        this.arrForm = []
      })
    },
    OrderASNReceiveLogSave1(row) {
      this.setFormData(row)
      this.$api.OrderASNReceiveLogSave({ ParamsList: this.arrForm }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.refreshCurrentChange()
          // 执行业务逻辑
        } else {
          this.$message.error('错误：', res.RetMsg)
        }
        this.arrForm = []
      })
      return
    },
    searchAd() {
      console.log(this.formSearch)
      if (this.formSearch.date.length === 2) {
        this.formSearch.BeginDate = this.formSearch.date[0]
        this.formSearch.EndDate = this.formSearch.date[1]
      }
      if (this.SelectType === 1) {
        this.getTableDataAsync()
      }
      if (this.SelectType === 2) {
        this.getTableDataAsync1()
      }
      this.visibl1e = false
    },
    Adsearch() {
      this.visibl1e = true
      this.adSearchForm = []
      if (this.SelectType === 1) {
        this.adSearchForm = this.adSearchForm1
      }
      if (this.SelectType === 2) {
        this.adSearchForm = this.adSearchForm2
      }
    },
    searchGetTableDataAsync() {
      if (this.SelectType === 1) {
        this.formSearch.PageIndex = 1
        this.getTableDataAsync()
      }
      if (this.SelectType === 2) {
        this.formSearch.PageIndex = 1
        this.getTableDataAsync1()
      }
    },
    selectType(type) {
      this.visible = false
      this.SelectType = type
      if (type === 1) {
        this.formSearch.PageIndex = 1
        this.getTableDataAsync()
      }
      if (type === 2) {
        this.formSearch.PageIndex = 1
        this.getTableDataAsync1()
      }
    },
    // 按订单
    async getTableDataAsync() {
      this.formSearch.PageSize = this.tablePageSize
      const response = await $orderReq.GetOrderDetailsMaterialList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    },
    // 交货单
    async getTableDataAsync1() {
      this.formSearch.PageSize = this.tablePageSize
      const response = await $orderReq.GetOrderDeliveryMaterialList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    },
    exportS() {
      this.visible1 = !this.visible1
    },
    // 导出
    ExportOrderDetailsMaterialList(type) {
      console.log(type)
      this.visible1 = false
      this.formSearch.ExportType = type ? 0 : 1
      if (type) {
        if (this.SelectType === 1) {
          // 全部
          $orderReq.ExportOrderDetailsMaterialList(this.formSearch).then(res => {
            console.log(res)
            if (res.RetCode === '0') {
              this.$minCommon.downloadFile(res.Message, '.xls')
            }
          })
        }

        if (this.SelectType === 2) {
          // 全部
          $orderReq.ExportOrderDeliveryMaterialList(this.formSearch).then(res => {
            console.log(res)
            if (res.RetCode === '0') {
              this.$minCommon.downloadFile(res.Message, '.xls')
            }
          })
        }
      }
      if (!type) {
        if (this.selects.length <= 0) return this.$message.info('没有选中任何一项！')
        const arr = []
        this.selects.forEach(item => {
          arr.push(item.IDX)
        })
        this.formSearch.IdxList = arr.join(',')

        if (this.SelectType === 1) {
          // 批量 SelectIDList
          $orderReq.ExportOrderDeliveryMaterialList(this.formSearch).then(res => {
            console.log(res)
            if (res.RetCode === '0') {
              this.$minCommon.downloadFile(res.Message, '.xls')
            }
          })
        }

        if (this.SelectType === 2) {
          // 批量 SelectIDList
          $orderReq.ExportOrderDeliveryMaterialListCheckBox(this.formSearch).then(res => {
            console.log(res)
            if (res.RetCode === '0') {
              this.$minCommon.downloadFile(res.Message, '.xls')
            }
          })
        }
      }
    }
  }
}
export default Index
