<template>
  <div class="goods-warehousing">
    <PageHeader title="订单物料清单" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          v-model="visible"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="cursor" @click="selectType(1)">按订单查询</div>
            <div class="p-tb-10 cursor" @click="selectType(2)">按交货单查询</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible = !visible">
            <div>{{ SelectType === 2 ? '按交货单查询' : '按订单查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
        <div class="out p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div>
      </div>
      <div class="flex m-left-20" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过订单编号、物料编号、物料名称进行搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="Adsearch">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            style="color: rgb(58, 120, 241)"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div class="flex">
        <div v-if="SelectType === 1" class="flex m-lr-10  a-center">
          <div class="left f14 p-right-20">交货数量</div>
          <el-select v-model="formSearch.Type" size="mini" style="width:150px" placeholder="请选择">
            <el-option
              v-for="item in TypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-popover
          v-model="visible1"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="ExportOrderDetailsMaterialList(1)">全部导出</div>
            <div class="cursor" @click="ExportOrderDetailsMaterialList(0)">按已选导出</div>
          </div>
          <div
            slot="reference"
            class="out   p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10 pointer"
            @click="exportS "
          >
            <i class="el-icon-upload2 f15 p-right-10" /> 导出
          </div>
        </el-popover>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        v-if="SelectType === 1"
        :data="tableData"
        style="width: 100%;"
        row-key="IDX"
        border
        :height="tableHeight"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="55" />
        <el-table-column prop="OrderID" label="订单编号" />
        <el-table-column prop="BuyCompanyName" label="买方" />
        <el-table-column prop="SendCompanyName" label="终端" />
        <el-table-column prop="InvCode" label="物料编号" />
        <el-table-column prop="ManuPartNum" label="原厂编号" />
        <el-table-column prop="InvName" label="物料名称" />
        <el-table-column prop="BrandName" label="品牌" />
        <el-table-column prop="" label="物料类型">
          <template slot-scope="scope">
            {{ scope.row.MaterialTypeName }} - {{ scope.row.MaterialSubTypeName }}
          </template>
        </el-table-column>
        <el-table-column prop="Specification" label="规格" />
        <el-table-column prop="StockUnitN" label="单位" />
        <el-table-column prop="MaterialStorageConditionName" label="储存条件" />
        <el-table-column prop="Num" label="订购数量" />
        <el-table-column prop="NumSend" label="已发数量" />
        <el-table-column prop="NumLeft" label="未清数量" />
        <el-table-column prop="NickName" label="创建人" />
        <el-table-column prop="WriteTime" label="创建时间" />
      </el-table>
      <el-table
        v-if="SelectType === 2"
        :data="tableData"
        style="width: 100%;"
        row-key="IDX"
        border
        :height="tableHeight"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="55" />
        <el-table-column prop="OrderID" label="订单编号" />
        <el-table-column prop="DeliveryID" label="交货单号" />
        <el-table-column prop="BuyCompanyName" label="买方" />
        <el-table-column prop="SendCompanyName" label="终端" />
        <el-table-column prop="InvCode" label="物料编号" />
        <el-table-column prop="ManuPartNum" label="原厂编号" />
        <el-table-column prop="InvName" label="物料名称" />
        <el-table-column prop="BrandName" label="品牌" />
        <el-table-column prop="Specification" label="规格" />
        <el-table-column prop="Num" label="数量" />
        <el-table-column prop="SapZCHRG" label="批号(流水号)">
          <template v-if="scope.row.SapZCHRG" slot-scope="scope">
            {{ scope.row.SapZCHRG }}({{ scope.row.SapCHARG }})
          </template>
        </el-table-column>
        <el-table-column prop="SapVFDAT" label="效期" />
        <el-table-column prop="WriteTime" label="创建时间" />
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page="formSearch.PageIndex"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="visibl1e" @cancel="(e) => visibl1e = e" @searchAd="searchAd" />
  </div>
</template>
<script>
import Index from '@/minxin/orderMananger/orderMaterialList'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
